import React from 'react'
import renderBlok from '@renderBlok'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { H3 } from '@system'
import { PageContainer, ContentContainer } from '@system'

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    padding: '50px 0px',
    justifyContent: 'center',
  },
  title: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightRegular,
    margin: 0,
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '20px',
    '&:first-child': {
      textAlign: 'left',
    },
    [theme.breakpoints.down('sm')]: {
      display: (props) =>
        !!props.blok.showAllCardsMobile && props.blok.showAllCardsMobile
          ? 'inherit'
          : 'none',
      marginBottom: '50px',
      '&:first-child': {
        display: 'flex',
        textAlign: 'left',
        alignItems: 'flex-start',
      },
    },
  },
}))

const FeaturedResourcesModule = (props) => {
  const classes = useStyles(props)
  return (
    <SbEditable content={props.blok}>
      <PageContainer>
        <ContentContainer>
          {!!props.blok.title && (
            <H3 className={classes.title}>{props.blok.title}</H3>
          )}
          {!!props.blok.resourceCards.length && (
            <Grid container direction="row" className={classes.outerContainer}>
              {props.blok.resourceCards.map((blok) => (
                <Grid
                  item
                  key={blok._uid}
                  xs={12}
                  sm={6}
                  md={4}
                  className={classes.items}
                >
                  {renderBlok(blok)}
                </Grid>
              ))}
            </Grid>
          )}
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default FeaturedResourcesModule
